// Tooltip.js
import React from 'react';
import ReactDOM from 'react-dom';
import './Tooltip.css';

const Tooltip = ({ text, position, targetRect }) => {
  if (!targetRect) return null;

  // Calculate tooltip position based on the target element's position
  const tooltipStyle = {
    top: targetRect.bottom + window.scrollY + 5, // 5px gap below the target
    left: targetRect.left + window.scrollX,      // Align to the left of the target
    zIndex: 1000,                                // Ensure it's on top
  };

  return ReactDOM.createPortal(
    <div className={`tooltip ${position}`} style={tooltipStyle}>
      {text}
      <span className="tooltip-arrow"></span>
    </div>,
    document.body
  );
};

export default Tooltip;
