import React, { useState, useEffect } from 'react';
import './Alerts.css'; // You can use CSS or styled-components for styling
import { ReactComponent as ArrowDown } from './assets/icons/arrow-down.svg';
import alertsOptions from './alertsOptions.json';

const Alerts = ({ isAuthenticated }) => {
  const [alertName, setAlertName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({
    regions: [],
    countries: [],
    authorities: [],
    industries: [],
    updateTypes: [],
    updateTopics: [],
  });
  const [frequency, setFrequency] = useState('instant');
  const [userAlerts, setUserAlerts] = useState([]);
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Fetch user's alerts when the component mounts
    fetch('/api/alerts', {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        setUserAlerts(data);
      })
      .catch(error => {
        console.error('Error fetching alerts:', error);
      });
  }, []);

  // Filtered options for countries and authorities
  const getCountriesForSelectedRegions = () => {
    if (selectedOptions.regions.length === 0) return [];
    return selectedOptions.regions.flatMap(region => alertsOptions.countries[region] || []);
  };

  const getAuthoritiesForSelectedRegionsAndCountries = () => {
    if (selectedOptions.regions.length === 0) return [];

    // Get all authorities for the selected regions
    let authorities = selectedOptions.regions.flatMap(region => alertsOptions.authorities[region]?.region || []);

    // Further filter by selected countries if any
    if (selectedOptions.countries.length > 0) {
      selectedOptions.countries.forEach(country => {
        selectedOptions.regions.forEach(region => {
          const countryAuthorities = alertsOptions.authorities[region]?.countries?.[country];
          if (countryAuthorities) {
            authorities = countryAuthorities;
          }
        });
      });
    }

    return authorities;
  };

  const handleOptionChange = (category, option) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [category]: prevSelectedOptions[category].includes(option)
        ? prevSelectedOptions[category].filter((item) => item !== option)
        : [...prevSelectedOptions[category], option],
    }));
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const handleCreateNewAlert = () => {
    // Reset all fields to default values
    setAlertName('');
    setSelectedOptions({
      regions: [],
      countries: [],
      authorities: [],
      industries: [],
      updateTypes: [],
      updateTopics: [],
    });
    setFrequency('instant');
    setSelectedAlertId(null);
  };

  const handleSelectAlert = (event) => {
    const alertId = event.target.value;
    if (alertId) {
      setSelectedAlertId(alertId);
      // Fetch the alert details from userAlerts and populate the fields
      const selectedAlert = userAlerts.find(alert => alert.id === parseInt(alertId));
      if (selectedAlert) {
        setAlertName(selectedAlert.alert_name);
        setSelectedOptions(selectedAlert.selectedOptions);
        setFrequency(selectedAlert.frequency);
      }
    } else {
      // If no alert is selected, reset the fields
      handleCreateNewAlert();
    }
  };

  const handleDeleteAlert = () => {
    if (selectedAlertId) {
      // Send a DELETE request to the backend to delete the alert
      fetch(`/api/alerts/${selectedAlertId}`, {
        method: 'DELETE',
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            // Remove the alert from the userAlerts state
            setUserAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== parseInt(selectedAlertId)));
            // Reset the form fields
            handleCreateNewAlert();
          } else {
            // Handle error
            console.error('Failed to delete alert');
          }
        })
        .catch(error => {
          console.error('Error deleting alert:', error);
        });
    } else {
      alert('Please select an alert to delete');
    }
  };

  // **Only one handleSubmit function should be defined**
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isAuthenticated) {
      setErrorMessage('You must be registered and logged in to create an alert.');
      return;
    }
    // Validate that the alertName is provided and at least one selected option is chosen
    const hasSelectedTopics = selectedOptions.updateTopics.length > 0;
    if (!hasSelectedTopics) {
      setErrorMessage('Please select at least one topic.');
      return;
    }
    // Prepare the alert data
    const alertData = {
      alert_name: alertName,
      selectedOptions: selectedOptions,
      frequency: frequency,
    };
    if (selectedAlertId) {
      // Update existing alert
      fetch(`/api/alerts/${selectedAlertId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(alertData),
      })
        .then(response => {
          if (response.ok) {
            // Update the alert in userAlerts
            setUserAlerts(prevAlerts => prevAlerts.map(alert => alert.id === parseInt(selectedAlertId) ? { ...alert, ...alertData } : alert));
            setSuccessMessage('Alert updated successfully.');
            setErrorMessage(''); // Clear any previous error messages

            
          } else {
            // Handle error
            console.error('Failed to update alert');
          }
        })
        .catch(error => {
          console.error('Error updating alert:', error);
        });
    } else {
      // Create new alert
      fetch('/api/alerts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(alertData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.alert_id) {
            // Add the new alert to userAlerts
            setUserAlerts(prevAlerts => [...prevAlerts, { id: data.alert_id, ...alertData }]);
            setSuccessMessage('Alert created successfully.');
            setErrorMessage(''); // Clear any previous error messages
            // Reset the form fields
            handleCreateNewAlert();
          } else {
            // Handle error
            console.error('Failed to create alert');
            setErrorMessage('Failed to create alert. Please try again.');
            setSuccessMessage(''); // Clear any previous success messages
          }
        })
        .catch(error => {
          console.error('Error creating alert:', error);
        });
    }
  };

  const MultiSelectDropdown = ({ options = [], selectedOptions, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State to track the user input

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
      onChange(option); // Toggle the selected option
    };

    const removeSelectedOption = (option) => {
      onChange(option); // Deselect the option
    };

    // Filter the options based on the search term
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <div className="multi-select-dropdown">
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="selected-options">
            {selectedOptions.length > 0 ? (
              selectedOptions.map((option, index) => (
                <span key={index} className="selected-option">
                  {option}
                  <span
                    className="remove-option"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from closing
                      removeSelectedOption(option); // Remove the selected option
                    }}
                  >
                    ✕
                  </span>
                  {index < selectedOptions.length - 1 && <span className="comma">,</span>} {/* Add space after the comma */}
                </span>
              ))
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </div>
          <ArrowDown className={`dropdown-arrow ${isOpen ? 'open' : ''}`} />
        </div>
        {isOpen && (
          <div className="dropdown-list">
            {/* Search input field */}
            <input
              type="text"
              className="dropdown-search-input"
              placeholder={`Search ${placeholder}...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {filteredOptions.map((option, index) => (
              <div
                key={index}
                className={`dropdown-list-item ${selectedOptions.includes(option) ? 'selected' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(option);
                }}
              >
                {option}
                {selectedOptions.includes(option) && <span> ✓</span>}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // **Only one return statement should be present**
  return (
    <div className="alerts-container">
      <main className="alerts-main">
        <header className="alerts-header" style={{ alignItems: 'center' }}>
          <h2 className="alerts-title roboto-light">ALERTS</h2>
        </header>
        <p className="alerts-description">
          Looking to get updates delivered straight to your inbox? Regxplora’s Alerts enable you to create customized notifications based on your specific needs. Choose to receive updates on specific topics, narrowed down to specific industries, types of updates, and designated regions, countries or authorities, ensuring you stay informed on the developments that matter most to you. Set up multiple alerts with custom names for easy reference and manage your preferences through the alerts menu, where you can modify or delete alerts at any time. Get started by clicking 'Add Alert'.
        </p>
        <div className="alerts-actions">
          <button className="btn btn-primary" onClick={handleCreateNewAlert}>Create new alert</button>
          <select className="btn btn-secondary" value={selectedAlertId || ''} onChange={handleSelectAlert}>
            <option value="">Modify existing alert</option>
            {userAlerts.map(alert => (
              <option key={alert.id} value={alert.id}>{alert.alert_name}</option>
            ))}
          </select>
          <button className="btn btn-danger" onClick={handleDeleteAlert}>Delete alert</button>
        </div>
        <form className="alert-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Set alert name and topic</label>
              <p className="alerts-description">
                Start by setting an alert name and selecting the topic(s) you would like to receive notifications on. 
              </p>
            <input
              type="text"
              id="alertName"
              placeholder="Name of alert"
              value={alertName}
              onChange={(e) => setAlertName(e.target.value)}
            />
            <MultiSelectDropdown
              options={alertsOptions.updateTopics}
              selectedOptions={selectedOptions.updateTopics}
              onChange={(option) => handleOptionChange('updateTopics', option)}
              placeholder="Select topic"
            />
          </div>

          <div className="form-group">
            <label>Set other filters</label>
            <p className="alerts-description">
              Narrow down the geographical scope of the updates on the selected topic(s) to specific regions, countries and/or authorities. Additionally, apply filters industries in scope or the nature of update. Simply choose your preferences from the options in the dropdown menu. If you have different preferences by topic, then we recommend setting up separate alerts.
            </p>
            <MultiSelectDropdown
              options={alertsOptions.regions}
              selectedOptions={selectedOptions.regions}
              onChange={(option) => handleOptionChange('regions', option)}
              placeholder="Select region"
            />
            <MultiSelectDropdown
              options={getCountriesForSelectedRegions()}
              selectedOptions={selectedOptions.countries}
              onChange={(option) => handleOptionChange('countries', option)}
              placeholder="Select country"
            />
            <MultiSelectDropdown
              options={getAuthoritiesForSelectedRegionsAndCountries()}
              selectedOptions={selectedOptions.authorities}
              onChange={(option) => handleOptionChange('authorities', option)}
              placeholder="Select authority"
            />
            <MultiSelectDropdown
              options={alertsOptions.industries}
              selectedOptions={selectedOptions.industries}
              onChange={(option) => handleOptionChange('industries', option)}
              placeholder="Select industry"
            />
            <MultiSelectDropdown
              options={alertsOptions.updateTypes}
              selectedOptions={selectedOptions.updateTypes}
              onChange={(option) => handleOptionChange('updateTypes', option)}
              placeholder="Select nature of update"
            />
          </div>
          <div className="form-group">
            <label>Set alert frequency</label>
            <p className="alerts-description">
              Set your preferred notification frequency. Daily emails are distributed at 10:30am UTC and weekly emails every Monday at 10:30am UTC. For daily and weekly notification frequency, will consolidate all news that match your preferences into a single email grouped by alert for your convenience.
            </p>
            <div
              className="radio-group"
              style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between', width: '15%' }}
            >
              <label className="radio-label" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="radio"
                  name="frequency"
                  value="instant"
                  checked={frequency === 'instant'}
                  onChange={handleFrequencyChange}
                /> Instant
              </label>
              <label className="radio-label" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="radio"
                  name="frequency"
                  value="daily"
                  checked={frequency === 'daily'}
                  onChange={handleFrequencyChange}
                /> Daily
              </label>
              <label className="radio-label" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="radio"
                  name="frequency"
                  value="weekly"
                  checked={frequency === 'weekly'}
                  onChange={handleFrequencyChange}
                /> Weekly
              </label>
            </div>
          </div>
          <button className="btn btn-savealert" type="submit">Save alert</button>
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </main>
    </div>
  );
};

export default Alerts;