import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Overview from "./Overview";
import Newsfeed from "./Newsfeed";
import Dashboard from "./Dashboard";
import Alerts from "./Alerts";
import Chat from "./Chat";
import Editor from "./Editor";
import Register from "./Register";
import Login from "./Login";
import Profile from "./Profile";
import "./App.css";
import { ClipboardProvider } from "./ClipboardContext";
import ResetPassword from "./ResetPassword";
import "./Burgermenu.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for programmatic navigation

  useEffect(() => {
    // Check if the user is already authenticated
    const checkAuth = async () => {
      const response = await fetch("/api/check_auth", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    const response = await fetch("/api/logout", {
      method: "POST",
      credentials: "include",
    });
    if (response.ok) {
      setIsAuthenticated(false);
      sessionStorage.removeItem('newsItemStatuses');
      sessionStorage.removeItem('clipboardItems');
      navigate("/login"); // Redirect to login page after logout
    }
  };

  const newsTrackerSections = [
    "dashboard",
    "newsfeed",
    "alerts",
    "chat",
    "editor",
  ];
  const currentPath = window.location.pathname.substring(1).toLowerCase();
  const isNewsTrackerActive = newsTrackerSections.includes(currentPath);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const burgermenuIsTouched = useRef(false);

  useEffect(() => {
    if (menuIsOpen) {
      burgermenuIsTouched.current = true;
    }
  }, [menuIsOpen]);
  const location = useLocation();
  useEffect(() => {
    // Close the menu on route change
    setMenuIsOpen(false);
  }, [location.pathname]);

  return (
    <div className="app-container">
      <div
        className={`burger-btn ${menuIsOpen ? "-menu-open" : ""}`}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      >
        <svg
          width="35px"
          height="35px"
          viewBox="0 0 48 48"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <line x1="0" y1="17" x2="48" y2="17" strokeWidth="2" />
            <line x1="0" y1="31" x2="48" y2="31" strokeWidth="2" />
          </g>
          <g>
            <line x1="0" y1="24" x2="48" y2="24" strokeWidth="2" />
            <line x1="0" y1="24" x2="48" y2="24" strokeWidth="2" />
          </g>
        </svg>
      </div>
      <aside
        className={`sidebar ${menuIsOpen && "open"} ${
          !menuIsOpen && burgermenuIsTouched.current && "close"
        }  `}
      >
        
        <div className="mobile-menu-header">REGXPLORA</div>

        <div className="sidebar-header">
          <h2 className="roboto-light">REGXPLORA</h2>
        </div>
        <nav>
          <ul>
            <li
              className={currentPath === "overview" ? "active" : ""}
              onClick={() => navigate("/overview")}
            >
              OVERVIEW
            </li>
            <li className={isNewsTrackerActive ? "active" : ""}>
              NEWS TRACKER
              <ul>
                <li
                  className={currentPath === "dashboard" ? "active" : ""}
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </li>
                <li
                  className={currentPath === "newsfeed" ? "active" : ""}
                  onClick={() => navigate("/newsfeed")}
                >
                  Newsfeed
                </li>
                <li
                  className={currentPath === "alerts" ? "active" : ""}
                  onClick={() => navigate("/alerts")}
                >
                  Alerts
                </li>
                <li
                  className={currentPath === "chat" ? "active" : ""}
                  onClick={() => navigate("/chat")}
                >
                  Chat
                </li>
                <li
                  className={currentPath === "editor" ? "active" : ""}
                  onClick={() => navigate("/editor")}
                >
                  Editor
                </li>
              </ul>
            </li>
            <li>SPECIALIZED TRACKERS</li>
            <li>RESEARCH & ANALYSIS</li>
          </ul>
          <div className="sidebar-bottom">
            <ul>
              {!isAuthenticated ? (
                <>
                  <li
                    className={`login-btn ${
                      currentPath === "login" ? "active" : ""
                    }`}
                    onClick={() => navigate("/login")}
                  >
                    LOGIN
                  </li>
                  <li
                    className={`register-btn ${
                      currentPath === "register" ? "active" : ""
                    }`}
                    onClick={() => navigate("/register")}
                  >
                    REGISTER
                  </li>
                </>
              ) : (
                <>
                  <li className="logout-btn" onClick={handleLogout}>
                    LOGOUT
                  </li>
                  <li
                    className={`profile-btn ${
                      currentPath === "profile" ? "active" : ""
                    }`}
                    onClick={() => navigate("/profile")}
                  >
                    MY PROFILE
                  </li>
                </>      

              )}
            </ul>
          </div>
        </nav>

        <div className="sidebar-copyright">
          <a
            href="https://www.regxelerator.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            © 2024 REGXELERATOR
          </a>
        </div>


      </aside>

      {/* Wrap the main content within the ClipboardProvider */}
      <ClipboardProvider
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      >
        <main className="main-content">
          <Routes>
            <Route path="/overview" element={<Overview />} />
            <Route
              path="/dashboard"
              element={
                <Navigate
                  to="/overview"
                  replace
                  state={{ showDashboardRestrictionModal: true }}
                />
              }
            />
            <Route
              path="/newsfeed"
              element={<Newsfeed isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/alerts"
              element={<Alerts isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/chat"
              element={<Chat isAuthenticated={isAuthenticated} />}
            />
            <Route path="/editor" element={<Editor />} />
            <Route
              path="/profile"
              element={<Profile setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/register"
              element={<Register setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/reset_password" element={<ResetPassword />} />{" "}
            {/* Add this line */}
            <Route path="/reset_password/:token" element={<ResetPassword />} />
            <Route path="/" element={<Navigate to="/newsfeed" />} />
            {/* Add a catch-all route for unmatched paths */}
            <Route path="*" element={<Navigate to="/newsfeed" />} />
          </Routes>
        </main>
      </ClipboardProvider>
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
