import React from "react";
import "./InfoModal.css"; // Add styles for the modal

const InfoModal = ({ title, text, buttonLabel, onClose }) => (
  <div className="info-modal-overlay">
    <div className="info-modal-content">
      <div className="info-modal-header">
        <h3>{title}</h3>
      </div>
      <div className="info-modal-body">
        <p>{text}</p>
      </div>
      <div className="info-modal-footer">
        <button className="info-modal-button" onClick={onClose}>
          {buttonLabel}
        </button>
      </div>
    </div>
  </div>
);

export default InfoModal;