import React, { useState } from 'react';
import './Register.css'; // Import the new CSS file for Register

function Register({ setIsAuthenticated }) {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    country: '',
    organization: '',
    position: '',
  });
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false); // State to track registration success

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    if (response.ok) {
      setIsAuthenticated(true);
      setIsRegistered(true); // Set success state
    } else {
      setError(data.error);
    }
  };

  // Success message screen
  if (isRegistered) {
    return (
      <div className="register-container">
        <main className="register-main">
          <header className="register-header">
            <h2 className="register-title roboto-light">REGISTER</h2>
          </header>
          <p className="register-success-message">
            Thank you for registering. Your profile has been successfully created.
          </p>
        </main>
      </div>
    );
  }

  // Registration form
  return (
    <div className="register-container">
      <main className="register-main">
        <header className="register-header">
          <h2 className="register-title roboto-light">REGISTER</h2>
        </header>
        <p className="register-description">
          Create a new account by filling out the information below.
        </p>
        {error && <p className="register-error">{error}</p>}
        <form className="register-form" onSubmit={handleSubmit}>
          {/* Line 1: First Name, Last Name */}
          <div className="register-row">
            <div className="register-form-group">
              <label htmlFor="first_name" className="register-label">First Name</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                className="register-input"
                placeholder="First Name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="last_name" className="register-label">Last Name</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                className="register-input"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Line 2: Email, Country */}
          <div className="register-row">
            <div className="register-form-group">
              <label htmlFor="email" className="register-label">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="register-input"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="country" className="register-label">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                className="register-input"
                placeholder="Country"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Line 3: Organization, Position */}
          <div className="register-row">
            <div className="register-form-group">
              <label htmlFor="organization" className="register-label">Organization</label>
              <input
                type="text"
                id="organization"
                name="organization"
                className="register-input"
                placeholder="Organization"
                value={formData.organization}
                onChange={handleChange}
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="position" className="register-label">Position</label>
              <input
                type="text"
                id="position"
                name="position"
                className="register-input"
                placeholder="Position"
                value={formData.position}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Line 4: Password */}
          <div className="register-row">
            <div className="register-form-group-full">
              <label htmlFor="password" className="register-label">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="register-input"
                placeholder="Create a Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Privacy Agreement */}
          <div className="radio-privacy-container">
            <div className="radio-privacy">
              <input type="radio" id="agree" name="consent" value="agree" />
              <label htmlFor="agree" className="radio-privacy-label">
                I agree with Regxelerator storing and processing my data
              </label>
            </div>
          </div>

          {/* Register Button */}
          <div className="register-section-buttons">
            <button className="register-section-btn register-btn-submit" type="submit">
              REGISTER
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default Register;