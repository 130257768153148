import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./Newsfeed.css";
import filterOptions from "./filterOptions.json"; // Import the JSON file
import { ReactComponent as IconOne } from "./assets/icons/icon1.svg";
import { ReactComponent as IconTwo } from "./assets/icons/icon2.svg";
import { ReactComponent as IconThree } from "./assets/icons/icon3.svg";
import { ReactComponent as IconFour } from "./assets/icons/icon4.svg";
import { ReactComponent as RefreshIcon } from "./assets/icons/refresh.svg";
import { debounce } from "lodash";
import { useClipboardContext } from "./ClipboardContext"; // Import clipboard context
import InfoModal from "./InfoModal"; // Import the reusable modal component
// import Tooltip from "./Tooltip"; // Removed unused import

const Newsfeed = ({ isAuthenticated }) => {
  const [showNoticeModal, setShowNoticeModal] = useState(true); // Notice modal state
  const [newsItems, setNewsItems] = useState([]);
  // const [userStatuses, setUserStatuses] = useState([]); // Removed unused state
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationRange, setPaginationRange] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    country: [],
    regional: [],
    global: [],
  });
  const { addClipboardItem } = useClipboardContext(); // Get function to add items to clipboard
  // const [activeTooltip, setActiveTooltip] = useState(null); // Removed unused state

  const [similarNewsType, setSimilarNewsType] = useState("country");
  const [loadingSimilarNews, setLoadingSimilarNews] = useState(false);

  const [filters, setFilters] = useState({
    date: "",
    region: "",
    country: "",
    authority: "",
    searchTerm: "",
    industry: "",
    updateType: "",
    topic: "",
    restrictions: "",
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Define fetchUserStatuses with useCallback
  const fetchUserStatuses = useCallback(async () => {
    try {
      const response = await axios.get("/api/news_item_statuses", {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data.statuses;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching user statuses:", error);
      return [];
    }
  }, []);

  // Define fetchData with useCallback
  const fetchData = useCallback(async () => {
    console.log("fetchData called with filters:", filters, "page:", page);
    setIsLoading(true);
    setError(null);
    try {
      const perPage = isAuthenticated ? 20 : 1000; // Adjust as needed

      // Initialize promises
      let statusesPromise = Promise.resolve([]);
      if (isAuthenticated) {
        statusesPromise = fetchUserStatuses();
      }

      // Fetch newsfeed and statuses concurrently
      const [newsfeedResponse, statuses] = await Promise.all([
        axios.get("/api/newsfeed", {
          params: { page, per_page: perPage, ...filters },
          withCredentials: true,
        }),
        statusesPromise,
      ]);

      let newsItemsWithStatus = newsfeedResponse.data.newsfeed;

      // Merge statuses with news items
      if (isAuthenticated) {
        const statusMap = new Map();
        statuses.forEach((status) => {
          statusMap.set(status.news_item_id, status);
        });
        newsItemsWithStatus = newsItemsWithStatus.map((item) => {
          const status = statusMap.get(item.ID);
          return {
            ...item,
            isRead: status ? status.is_read : false,
            isFlagged: status ? status.is_flagged : false,
          };
        });
        console.log("Fetched newsItemsWithStatus for authenticated user:", newsItemsWithStatus);
      } else {
        // Load statuses from Session Storage for non-logged-in users
        const storedStatuses =
          JSON.parse(sessionStorage.getItem("newsItemStatuses")) || [];
        newsItemsWithStatus = newsItemsWithStatus.map((item) => {
          const storedStatus = storedStatuses.find(
            (status) => status.ID === item.ID
          );
          return {
            ...item,
            isRead: storedStatus ? storedStatus.isRead : false,
            isFlagged: storedStatus ? storedStatus.isFlagged : false,
          };
        });

        console.log("Fetched paginated newsItems for non-authenticated user:", newsItemsWithStatus);

        // Apply restrictions filter for non-authenticated users
        if (filters.restrictions) {
          if (filters.restrictions === "Exclude mark as read") {
            newsItemsWithStatus = newsItemsWithStatus.filter(
              (item) => !item.isRead
            );
          } else if (filters.restrictions === "Show only bookmarked") {
            newsItemsWithStatus = newsItemsWithStatus.filter(
              (item) => item.isFlagged
            );
          }
        }

        // Implement frontend pagination
        const itemsPerPage = 20;
        const totalItems = newsItemsWithStatus.length;
        const totalPagesCalculated = Math.ceil(totalItems / itemsPerPage);
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const paginatedItems = newsItemsWithStatus.slice(startIndex, endIndex);

        setNewsItems(paginatedItems);
        setTotalPages(totalPagesCalculated);
        setPaginationRange(calculatePaginationRange(page, totalPagesCalculated));
        return; // Exit the function early for non-authenticated users
      }

      // For authenticated users, set the news items as usual
      setNewsItems(newsItemsWithStatus);
      setTotalPages(newsfeedResponse.data.pages);
      setPaginationRange(
        calculatePaginationRange(page, newsfeedResponse.data.pages)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch news items. Please try again later.");
      setNewsItems([]); // Clear news items on error
    } finally {
      setIsLoading(false);
    }
  }, [filters, page, isAuthenticated, fetchUserStatuses]);

  // Debounced fetchData
  const debouncedFetchData = useCallback(
    debounce(() => {
      fetchData();
    }, 500),
    [fetchData] // Now depends on fetchData
  );

  // Effect that runs the debounced function whenever filters, page, or authentication status change
  useEffect(() => {
    debouncedFetchData();
  }, [debouncedFetchData]); // Only depends on debouncedFetchData

  // Automatic data refresh every 5 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData(); // This will refresh the data
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [fetchData]); // Now depends on fetchData

  const handleRefresh = () => {
    fetchData(); // Refetch data
  };

  // Function to save statuses to Session Storage
  const saveStatusesToSessionStorage = (updatedItems) => {
    // Extract the statuses and IDs
    const statuses = updatedItems.map((item) => ({
      ID: item.ID,
      isRead: item.isRead,
      isFlagged: item.isFlagged,
    }));
    sessionStorage.setItem("newsItemStatuses", JSON.stringify(statuses));
  };

  const handleMarkAsRead = (id) => {
    setNewsItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.ID === id ? { ...item, isRead: !item.isRead } : item
      );

      if (isAuthenticated) {
        const updatedItem = updatedItems.find((item) => item.ID === id);
        // Send API request to update status
        axios
          .post(
            "/api/news_item_statuses",
            {
              news_item_id: parseInt(id), // Ensure it's an integer
              is_read: updatedItem.isRead,
              is_flagged: updatedItem.isFlagged, // Include is_flagged to update both statuses
            },
            { withCredentials: true }
          )
          .catch((error) => {
            console.error("Error updating status:", error);
          });
      } else {
        // For non-logged-in users, save to Session Storage
        saveStatusesToSessionStorage(updatedItems);
      }

      return updatedItems;
    });
  };

  const handleFlagForLater = (id) => {
    setNewsItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.ID === id ? { ...item, isFlagged: !item.isFlagged } : item
      );

      if (isAuthenticated) {
        const updatedItem = updatedItems.find((item) => item.ID === id);
        // Send API request to update status
        axios
          .post(
            "/api/news_item_statuses",
            {
              news_item_id: parseInt(id), // Ensure it's an integer
              is_read: updatedItem.isRead, // Include is_read to update both statuses
              is_flagged: updatedItem.isFlagged,
            },
            { withCredentials: true }
          )
          .catch((error) => {
            console.error("Error updating status:", error);
          });
      } else {
        // For non-logged-in users, save to Session Storage
        saveStatusesToSessionStorage(updatedItems);
      }

      return updatedItems;
    });
  };

  const handleExport = async () => {
    let exportData = [];

    if (exportOption === "option1") {
      // Export all visible entries
      exportData = newsItems.slice(0, 200); // Limit to 200 entries
    } else if (exportOption === "option2") {
      // Export selected entries
      exportData = newsItems.filter((item) => selectedItems.includes(item.ID));
    } else if (exportOption === "option3") {
      // Export bookmarked entries
      exportData = newsItems.filter((item) => item.isFlagged);
    }

    if (exportData.length > 0) {
      // Send the export data to the backend
      try {
        const response = await axios.post(
          "/api/export_newsfeed",
          {
            export_type: exportOption,
            newsfeed: exportData,
          },
          {
            responseType: "blob", // Important for file download
          }
        );

        // Trigger download of the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "newsfeed_export.xlsx"); // Set the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Clean up
      } catch (error) {
        console.error("Error exporting newsfeed:", error);
      }
    } else {
      alert("No data available for export.");
    }
  };

  const [exportOption, setExportOption] = useState(""); // Track export option
  const [selectedItems, setSelectedItems] = useState([]); // Track selected entries

  const handleSelectionChange = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const calculatePaginationRange = (currentPage, totalPages) => {
    const maxButtons = 5;
    const halfRange = Math.floor(maxButtons / 2);
    let startPage = Math.max(currentPage - halfRange, 1);
    let endPage = Math.min(currentPage + halfRange, totalPages);

    if (currentPage <= halfRange) {
      endPage = Math.min(maxButtons, totalPages);
    } else if (currentPage + halfRange >= totalPages) {
      startPage = Math.max(totalPages - maxButtons + 1, 1);
    }

    const range = [];
    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }
    return range;
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      date: "",
      region: "",
      country: "",
      authority: "",
      searchTerm: "",
      industry: "",
      updateType: "",
      topic: "",
      restrictions: "",
    });
  };

  const handleSimilarNews = async (id) => {
    setLoadingSimilarNews(true);
    setShowModal(true);
    try {
      const response = await axios.post("/api/similar_news", { id });
      if (response.status === 200) {
        const similarNewsData = response.data;
        setModalContent({
          country: similarNewsData.country_similar_news,
          regional: similarNewsData.regional_similar_news,
          global: similarNewsData.global_similar_news,
        });
        setSimilarNewsType("country"); // Set default to 'country'
        setLoadingSimilarNews(false);
      }
    } catch (error) {
      console.error("Error fetching similar news:", error);
      setLoadingSimilarNews(false); // Ensure loading state is reset
    }
  };

  // Function to format date strings to 'YYYY-MM-DD'
  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    return dateStr.substring(0, 10);
  };

  const [filterIsCollapsed, setFilterIsCollapsed] = useState(true);

  return (
    <div className="newsfeed-container">

      {/* Notice Modal */}
      {showNoticeModal && (
        <InfoModal
          title="IMPORTANT NOTICE"
          text="Regxplora's data migration is in the very final stages. While the migration remains underway, this can affect the quality and comprehensiveness of Regxplora's output."
          buttonLabel="I understand"
          onClose={() => setShowNoticeModal(false)}
        />
      )}

      <main className="newsfeed-main">
        <header className="newsfeed-header" style={{ alignItems: "center" }}>
          <h2 className="newsfeed-title roboto-light">NEWSFEED</h2>
        </header>
        <p className="newsfeed-description">
          Regxplora's newsboard delivers 24/7 updates from close to 700 global sources, including financial regulatory authorities, central banks,
          global and regional standard setters, national ministries of finance, treasuries, industry associations, and advocacy groups. Updates are
          retrieved from primary sources and processed using generative AI to create summaries and classify them by industry, type, and topic,
          ensuring you quickly find the information most relevant to you. Easily export updates, copy updates to the editor for further processing,
          search for related content, and mark items as read or flag them for later review. Regxplora currently houses updates from January 1, 2024 onwards.  
          <span style={{ color: "#000", fontSize: "14", fontWeight: "bold" }}> Note: All Regxplora summaries are created using generative AI. Errors may occur. </span>
        </p>

        {/* Filter Bar Section */}
        <section className="filters-section">
          <h3 className="hide-mobile">Filters</h3>
          <div
            className="hide-desktop filter-collapse"
            onClick={() => setFilterIsCollapsed(!filterIsCollapsed)}
          >
            <div className="pos-relative">
              <h3 className="filter-coll-btn">Filters</h3>
              <svg
                className={`abs-arrow-btn ${filterIsCollapsed && "rotate-all"}`}
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </div>
          {/* <hr
            className={`custom-divider ${
              filterIsCollapsed && "force-collapse"
            }`}
          /> */}

          {/* Sub-section 1: Dropdowns for date, region, country, authority */}
          <div
            className={`filters-subsection-container ${
              filterIsCollapsed && "force-collapse"
            }`}
          >
            <div className="filters-subsection">
              <div className="filters-row">
                <select
                  name="date"
                  value={filters.date}
                  onChange={handleFilterChange}
                >
                  <option value="">Select date</option>
                  {filterOptions.date.map((date, index) => (
                    <option key={index} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
                <select
                  name="region"
                  value={filters.region}
                  onChange={handleFilterChange}
                >
                  <option value="">Select region</option>
                  {filterOptions.regions.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
                <select
                  name="country"
                  value={filters.country}
                  onChange={handleFilterChange}
                >
                  <option value="">Select country</option>
                  {filterOptions.countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <select
                  name="authority"
                  value={filters.authority}
                  onChange={handleFilterChange}
                >
                  <option value="">Select authority</option>
                  {filterOptions.authorities.map((authority, index) => (
                    <option key={index} value={authority}>
                      {authority}
                    </option>
                  ))}
                </select>
                <select
                  name="industry"
                  value={filters.industry}
                  onChange={handleFilterChange}
                >
                  <option value="">Select industry</option>
                  {filterOptions.industries.map((industry, index) => (
                    <option key={index} value={industry}>
                      {industry}
                    </option>
                  ))}
                </select>
                <select
                  name="updateType"
                  value={filters.updateType}
                  onChange={handleFilterChange}
                >
                  <option value="">Select nature of update</option>
                  {filterOptions.updateTypes.map((updateType, index) => (
                    <option key={index} value={updateType}>
                      {updateType}
                    </option>
                  ))}
                </select>
                <select
                  name="topic"
                  value={filters.topic}
                  onChange={handleFilterChange}
                >
                  <option value="">Select topic</option>
                  {filterOptions.topics.map((topic, index) => (
                    <option key={index} value={topic}>
                      {topic}
                    </option>
                  ))}
                </select>
                <select
                  name="restrictions"
                  value={filters.restrictions}
                  onChange={handleFilterChange}
                >
                  <option value="">Other restrictions</option>
                  {filterOptions.restrictions.map((restriction, index) => (
                    <option key={index} value={restriction}>
                      {restriction}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Sub-section 2: Dropdowns for industry, nature of update, topic, restrictions */}

          {/* Sub-section 3: Search field */}
          <div
            className={`filters-subsection-container ${
              filterIsCollapsed && "force-collapse"
            }`}
          >
            <div className="filters-subsection">
              <div className="filters-row long-input">
                <input
                  type="text"
                  name="searchTerm"
                  className="search-input"
                  value={filters.searchTerm}
                  placeholder="Enter search term"
                  onChange={handleFilterChange}
                />
              </div>
            </div>
          </div>
          {/* Sub-section 4: Buttons for clear filter, refresh, export dropdown, export button */}
          <div className="filters-subsection">
            <div className="filters-actions-container">
              <div
                className={`left-actions ${
                  filterIsCollapsed && "force-collapse"
                }`}
              >
                <button className="clear-filters" onClick={clearFilters}>
                  Clear filters
                </button>
                <button
                  className="refresh-button hide-mobile"
                  onClick={handleRefresh}
                >
                  <RefreshIcon />
                </button>
              </div>
              <div className="right-actions">
                {/* Export Dropdown */}
                <select
                  className="export-options-dropdown"
                  value={exportOption}
                  onChange={(e) => setExportOption(e.target.value)}
                >
                  <option value="">Select export options</option>
                  <option value="option1">Export all visible</option>
                  <option value="option2">Export selected only</option>
                  <option value="option3">Export bookmarked only</option>
                </select>
                {/* Export Button */}
                <button className="export-button" onClick={handleExport}>
                  Export
                </button>
              </div>

              <button
                className="refresh-button hide-desktop"
                onClick={handleRefresh}
              >
                <RefreshIcon />
              </button>
            </div>
          </div>
        </section>

      {/* Modal Popup */}
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h3 className="roboto-light">SIMILAR NEWS</h3>
              <div className="modal-header-actions">
                <button
                  className={`toggle-button ${similarNewsType === "country" ? "active" : ""}`}
                  onClick={() => setSimilarNewsType("country")}
                >
                  Country
                </button>
                <button
                  className={`toggle-button ${similarNewsType === "regional" ? "active" : ""}`}
                  onClick={() => setSimilarNewsType("regional")}
                >
                  Region
                </button>
                <button
                  className={`toggle-button ${similarNewsType === "global" ? "active" : ""}`}
                  onClick={() => setSimilarNewsType("global")}
                >
                  Global
                </button>
                <button
                  className="close-modal-button"
                  onClick={() => setShowModal(false)}
                >
                  X
                </button>
              </div>
            </div>
            <div className="modal-body">
              {loadingSimilarNews ? (
                <p>Request being processed...</p>
              ) : (
                <>
                  {modalContent[similarNewsType] &&
                  modalContent[similarNewsType].length > 0 ? (
                    modalContent[similarNewsType].map((similarItem) => (
                      <div key={similarItem.ID} className="modal-news-item">
                        <div className="modal-news-item-content">
                          <p className="modal-news-date">
                            {formatDate(similarItem.PublicationDate)}
                          </p>
                          <p className="modal-news-authority">
                            {similarItem.Authority}
                          </p>
                          <h4 className="modal-news-title">
                            <a
                              href={similarItem.NewsLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {similarItem.NewsTitle || "No Title Available"}
                            </a>
                          </h4>
                          <p className="modal-news-summary">
                            {similarItem.NewsSummary}
                          </p>
                        </div>
                        <div className="modal-news-item-actions">
                          <button
                            className="icon-button-newsfeed"
                            onClick={() =>
                              addClipboardItem(similarItem.NewsSummary)
                            }
                            aria-label="Add to Clipboard"
                            title="Add to clipboard"
                          >
                            <IconFour className="action-icon2" />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No similar news found.</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

        {/* News Items Section */}
        <section className="news-summary-section news-sum-sec-desktop">
          <div className="news-summary-header">
            <span className="column-checkbox">Select</span>
            <span className="column-date">Date</span>
            <span className="column-summary">News Summary (100% AI-generated)</span>
            <span className="column-actions advanced-link">Advanced</span>
          </div>

          {error && <p className="error-message">{error}</p>}
          {isLoading ? (
            <p>Loading news items...</p>
          ) : newsItems.length > 0 ? (
            newsItems.map((item) => (
              <div
                key={item.ID}
                className={`news-summary-item ${item.isRead ? "read" : ""}`}
              >
                <div className="column-checkbox news-summary-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item.ID)}
                    onChange={() => handleSelectionChange(item.ID)}
                  />
                </div>
                <div className="column-date news-summary-date">
                  {item.DatePublication}
                </div>
                <div className="column-summary news-summary-content">
                  <p className="news-summary-title">
                    {item.Region} | {item.Country} | {item.Authority}
                  </p>
                  <h3 className="news-summary-heading">
                    <a
                      href={item.NewsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.NewsTitle}
                    </a>
                  </h3>
                  <p className="news-summary-description">{item.NewsSummary}</p>
                </div>
                <div className="column-actions news-summary-actions">
                  
                  <button
                    className="icon-button-newsfeed"
                    onClick={() => handleMarkAsRead(item.ID)}
                    aria-label="Mark as Read"
                    title="Mark as read"
                  >
                    <IconOne
                      className={`action-icon ${item.isRead ? "icon-read" : ""}`}
                    />
                  </button>

                  <button
                    className="icon-button-newsfeed"
                    onClick={() => handleFlagForLater(item.ID)}
                    aria-label="Flag for Later"
                    title="Bookmark for later review"
                  >
                    <IconTwo
                      className={`action-icon ${
                        item.isFlagged ? "icon-flagged" : ""
                      }`} // Apply 'icon-flagged' class if flagged
                    />
                  </button>

                  <button
                    className="icon-button-newsfeed"
                    onClick={() => handleSimilarNews(item.ID)}
                    aria-label="Find Similar News"
                    title="Find similar news"
                  >
                    <IconThree className="action-icon" />
                  </button>

                  <button
                    className="icon-button-newsfeed"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling up
                      addClipboardItem(item.NewsSummary);
                    }}
                    aria-label="Add to Clipboard"
                    title="Add to clipboard"
                  >
                    <IconFour className="action-icon2" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No news items available.</p>
          )}

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button disabled={page <= 1} onClick={() => setPage(page - 1)}>
              Previous
            </button>
            {paginationRange.map((pageNumber) => (
              <span
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
                className={`page-number ${page === pageNumber ? "active" : ""}`}
              >
                {pageNumber}
              </span>
            ))}
            <button
              disabled={page >= totalPages}
              onClick={() => setPage(page + 1)}
            >
              Next
            </button>
          </div>
        </section>

        <section className="news-summary-section news-sum-sec-mobile">
          <div className="news-summary-header">
            <span className="column-checkbox">Select</span>
            <span className="column-date">Date</span>
            <span className="column-summary">News Summary (100% AI-generated)</span>
            <span className="column-actions advanced-link">Advanced</span>
          </div>

          {error && <p className="error-message">{error}</p>}
          {isLoading ? (
            <p>Loading news items...</p>
          ) : newsItems.length > 0 ? (
            newsItems.map((item) => (
              <div
                key={item.ID}
                className={`news-summary-item ${item.isRead ? "read" : ""}`}
              >
                <div className="mobile-filter-btns-news-col">
                  <div className="mobile-filter-btns-news">
                    <div className="column-date news-summary-date">
                      <div>{formatDate(item.DatePublication)}</div>
                    </div>

                    <div className="column-actions news-summary-actions">
                      <div className="cover-icon">
                        <input
                          className="checkbox-mob-feed"
                          type="checkbox"
                          checked={selectedItems.includes(item.ID)}
                          onChange={() => handleSelectionChange(item.ID)}
                        />
                      </div>
                      <button
                        className="icon-button-newsfeed"
                        onClick={() => handleMarkAsRead(item.ID)}
                        aria-label="Mark as Read"
                      >
                        <IconOne
                          className={`action-icon ${
                            item.isRead ? "icon-read" : ""
                          }`}
                        />
                      </button>

                      <button
                        className="icon-button-newsfeed"
                        onClick={() => handleFlagForLater(item.ID)}
                        aria-label="Flag for Later"
                      >
                        <IconTwo
                          className={`action-icon ${
                            item.isFlagged ? "icon-flagged" : ""
                          }`} // Apply 'icon-flagged' class if flagged
                        />
                      </button>

                      <button
                        className="icon-button-newsfeed"
                        onClick={() => handleSimilarNews(item.ID)}
                        aria-label="Find Similar News"
                      >
                        <IconThree className="action-icon" />
                      </button>

                      <button
                        className="icon-button-newsfeed"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          addClipboardItem(item.NewsSummary);
                        }}
                        aria-label="Add to Clipboard"
                        title="Add to clipboard"
                      >
                        <IconFour className="action-icon2" />
                      </button>
                    </div>
                  </div>

                  <div className="column-checkbox news-summary-checkbox">
                    <p className="news-summary-title">
                      {item.Region} | {item.Country} | {item.Authority}
                    </p>
                  </div>
                </div>

                <div className="column-summary news-summary-content">
                  <h3 className="news-summary-heading">
                    <a
                      href={item.NewsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.NewsTitle}
                    </a>
                  </h3>
                  <p className="news-summary-description">{item.NewsSummary}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No news items available.</p>
          )}

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button disabled={page <= 1} onClick={() => setPage(page - 1)}>
              Previous
            </button>
            {paginationRange.map((pageNumber) => (
              <span
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
                className={`page-number ${page === pageNumber ? "active" : ""}`}
              >
                {pageNumber}
              </span>
            ))}
            <button
              disabled={page >= totalPages}
              onClick={() => setPage(page + 1)}
            >
              Next
            </button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Newsfeed;