import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as SendIcon } from "./assets/icons/send-icon.svg"; // Adjust the path if needed
import { useClipboardContext } from "./ClipboardContext"; // Import clipboard context
import { marked } from "marked";
import InfoModal from "./InfoModal"; // Import the reusable modal component
import "./Chat.css";
import io from "socket.io-client"; // Import Socket.IO client

const Chat = () => {
  const [showNoticeModal, setShowNoticeModal] = useState(true); // Notice modal state
  const [conversation, setConversation] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const conversationEndRef = useRef(null);
  const conversationRef = useRef([]);
  const { addClipboardItem } = useClipboardContext(); // Get function to add items to clipboard
  const [responseStyle, setResponseStyle] = useState("Select response style");
  const [responseLength, setResponseLength] = useState("Select response length");
  const [responseFormat, setResponseFormat] = useState("Select response format");

  // Typing queue and typing flag
  const typingQueueRef = useRef([]);
  const isTypingRef = useRef(false);

  const [socket, setSocket] = useState(null); // State to hold the socket instance

  useEffect(() => {
    // Initialize Socket.IO connection
    const newSocket = io(); // Defaults to connecting to the host that serves the page
    setSocket(newSocket);

    // Log connection status
    newSocket.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    // Cleanup on unmount
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("response_chunk", (data) => {
      console.log("Chunk received:", data.chunk);

      if (data.chunk === "[DONE]") {
        isTypingRef.current = false; // Reset typing flag
        console.log("Streaming complete");
        return;
      }

      if (data.chunk.startsWith("[Error]")) {
        // Handle errors if needed
        return;
      }

      if (data.chunk && data.chunk !== "[DONE]") {
        typingQueueRef.current.push(data.chunk.replace(/\[DONE\]/g, '')); // Remove [DONE] from chunk
        if (!isTypingRef.current) {
          typeNextChunk();
        }
      }
    });

    // Optional: Handle socket errors
    socket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
    });

    // Cleanup listeners on unmount or when socket changes
    return () => {
      socket.off("response_chunk");
      socket.off("connect_error");
    };
  }, [socket]);

  const typeNextChunk = () => {
    if (typingQueueRef.current.length === 0) {
      isTypingRef.current = false;
      return;
    }

    isTypingRef.current = true;
    const currentChunk = typingQueueRef.current.shift();
    let index = 0;

    const typeCharacter = () => {
      if (index < currentChunk.length) {
        const nextChar = currentChunk[index];
        index++;
        setConversation((prev) => {
          const updated = [...prev];
          const lastMessage = updated[updated.length - 1];

          if (lastMessage && lastMessage.role === "assistant") {
            // Append character to existing assistant message
            updated[updated.length - 1] = {
              ...lastMessage,
              content: lastMessage.content + nextChar,
            };
          } else {
            // If no assistant message present, create one
            updated.push({
              role: "assistant",
              content: nextChar,
            });
          }
          return updated;
        });
        setTimeout(typeCharacter, 5);
      } else {
        // Completed this chunk, move on to the next one
        typeNextChunk();
      }
    };

    typeCharacter();
  };

  const scrollToBottom = () => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest", // Ensures only the chat output field scrolls, not the full page
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Set default values if selections are not made
    const style =
      responseStyle === "Select response style" ? "formal" : responseStyle;
    const length =
      responseLength === "Select response length" ? "long" : responseLength;
    const format =
      responseFormat === "Select response format"
        ? "bullet_points"
        : responseFormat;

    // Check if the user input meets the minimum character requirement
    if (userInput.trim().length < 20) {
      setErrorMessage("Your message must be at least 20 characters long.");
      return;
    }

    // Clear error message if all validations pass
    setErrorMessage("");

    const newMessage = { role: "user", content: "\n" + userInput };

    // Update conversationRef
    conversationRef.current = [...conversationRef.current, newMessage];

    // Update conversation state for rendering
    setConversation([...conversationRef.current]);

    setUserInput(""); // Clear input field immediately after submission

    // Add placeholder for assistant's message
    const assistantPlaceholder = { role: "assistant", content: "" };
    conversationRef.current = [
      ...conversationRef.current,
      assistantPlaceholder,
    ];
    setConversation([...conversationRef.current]);

    // Emit the 'send_message' event via Socket.IO
    if (socket) {
      console.log("Emitting send_message event with data:", {
        instructions: "You are a helpful assistant.",
        user_input: userInput,
        response_style: style,
        response_length: length,
        response_format: format,
      });

      socket.emit("send_message", {
        instructions: "You are a helpful assistant.",
        user_input: userInput,
        response_style: style,
        response_length: length,
        response_format: format,
      });
    } else {
      console.error("Socket not initialized");
      setConversation((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "[Error] Socket not initialized.",
          type: "error",
        },
      ]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleClear = () => {
    fetch("/api/clear_chat", { method: "POST" })
      .then((response) => {
        if (response.ok) {
          setConversation([]); // Clear conversation state
          conversationRef.current = []; // Reset conversationRef
          setUserInput(""); // Clear user input field
        }
      })
      .catch((error) => console.error("Error clearing chat:", error));
  };


  const renderMessageContent = (msg) => {
    if (msg.role === "assistant") {
      // Replace newlines to handle streaming properly
      const processedContent = msg.content
        .replace(/\n/g, "") // Remove newlines for streaming compatibility
        .replace(/(?<!\n)- /g, "\n- ") // Add newline before top-level bullets
        .replace(/(?<!\n)\s{2}- /g, "\n  - ") // Add newline for sub-bullets (indented)
        .replace(/(?<!\n)\s{4}- /g, "\n    - "); // Add newline for deeper nesting
  
      // Use marked to convert processed Markdown into HTML
      const htmlContent = marked(processedContent, {
        headerIds: false,
        mangle: false,
      });
  
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    return <span style={{ whiteSpace: "pre-wrap" }}>{msg.content}</span>;
  };


  return (
    <div className="chat-container">
      
      {/* Notice Modal */}
      {showNoticeModal && (
        <InfoModal
          title="IMPORTANT NOTICE"
          text="Regxplora's data migration is in the very final stages. While the migration remains underway, this can affect the quality and comprehensiveness of Regxplora's output."
          buttonLabel="I understand"
          onClose={() => setShowNoticeModal(false)}
        />
      )}

      <main className="chat-main">
        <header className="chat-header" style={{ alignItems: "center" }}>
          <h2 className="chat-title roboto-light">CHAT</h2>
        </header>
        <p className="chat-description">
          Regxplora’s Chat feature enables direct interaction with Regxplora's
          up-to-date news database. Utilizing a bespoke information retrieval
          technique, you can pose specific questions on latest updates, trends
          across regions and other focused insights. Customize responses by
          style, length, and format tailored to your preferences, and easily
          copy content to the editor for further refinement. Stay tuned for the
          launch of a dedicated research capability during the first half of 2025 for an even more
          systematic research and analysis of financial regulatory and central
          bank intelligence.
        </p>

        {/* Split into two columns */}
        <div className="chat-columns">
          {/* Left Column: 3/4 width */}
          <div className="chat-left-column">
            {/* Chat Output Field (Non-Editable) */}
            <div className="chat-output-container">
              <div id="conversation" className="chat-output-field">
                {conversation.map((msg, index) => (
                  <div key={index} className={`message ${msg.role}`}>
                    <strong className={`message-role ${msg.role}`}>
                      {msg.role === "user" ? "User" : "Assistant"}:
                    </strong>
                    <div className="message-content">
                      {renderMessageContent(msg)}{" "}
                      {/* This ensures each message starts on a new line */}
                    </div>
                  </div>
                ))}
                <div ref={conversationEndRef} />
              </div>
            </div>

            {/* Chat Input Field with Button */}
            <div className="chat-input-container">
              <form
                id="chat-form"
                onSubmit={handleSubmit}
                className="input-with-button"
              >
                <textarea
                  name="user_input"
                  className="chat-input-field"
                  placeholder="Enter your question here..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyDown} // Add this line
                  // autoFocus
                  rows="3"
                ></textarea>
                {/* Button with the SendIcon component placed inside the input field */}
                <button type="submit" className="send-button">
                  <SendIcon className="send-icon" />
                </button>
              </form>
              {errorMessage && (
                <p className="error-message">{errorMessage}</p> // Error message displayed here
              )}
            </div>

            <button
              type="button"
              className="action-button action-button-mobile"
              onClick={(e) => {
                e.stopPropagation();
                const selectedText = window.getSelection().toString();
                if (selectedText) {
                  addClipboardItem(selectedText);
                } else {
                  console.warn("No text selected to copy.");
                }
              }}
            >
              Copy selection to clipboard
            </button>
            <button
              type="button"
              className="action-button action-button-mobile"
              onClick={handleClear}
            >
              Clear chat
            </button>
          </div>

          {/* Right Column: 1/4 width */}
          <div className="chat-right-column">
            <select
              className="response-options-dropdown"
              value={responseStyle}
              onChange={(e) => setResponseStyle(e.target.value)}
            >
              <option>Select response style</option>
              <option value="formal">Formal</option>
              <option value="informal">Casual</option>
            </select>

            <select
              className="response-options-dropdown"
              value={responseLength}
              onChange={(e) => setResponseLength(e.target.value)}
            >
              <option>Select response length</option>
              <option value="short">Concise</option>
              <option value="long">Detailed</option>
            </select>

            <select
              className="response-options-dropdown"
              value={responseFormat}
              onChange={(e) => setResponseFormat(e.target.value)}
            >
              <option>Select response format</option>
              <option value="bullet_points">Bullet points</option>
              <option value="paragraph">Continuous prose</option>
            </select>
            <button
              type="button"
              className="action-button"
              onClick={(e) => {
                e.stopPropagation();
                const selectedText = window.getSelection().toString();
                if (selectedText) {
                  addClipboardItem(selectedText);
                } else {
                  console.warn("No text selected to copy.");
                }
              }}
            >
              Copy selection to clipboard
            </button>
            <button
              type="button"
              className="action-button"
              onClick={handleClear}
            >
              Clear chat
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Chat;