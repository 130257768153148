import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as SendIcon } from "./assets/icons/send-icon.svg";
import { ReactComponent as CopyIcon } from "./assets/icons/copy-icon.svg";
import { ReactComponent as TrashIcon } from "./assets/icons/trash-icon.svg";
import { useClipboardContext } from "./ClipboardContext";
import "./Editor.css";
import Tooltip from "./Tooltip";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";

const Editor = () => {
  const [outputContent, setOutputContent] = useState("");
  const [inputInstruction, setInputInstruction] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { clipboardItems, removeClipboardItem, clearClipboard } = useClipboardContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [charLimitError, setCharLimitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Tooltip state
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState("right");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipRect, setTooltipRect] = useState(null);
  const tooltipTimeoutRef = useRef(null);

  // Initialize TipTap editor
  const editor = useEditor({
    extensions: [StarterKit, Markdown],
    content: outputContent,
    onUpdate: ({ editor }) => {
      const markdown = editor.getText();
      setOutputContent(markdown);
    },
  });

  const handleCopyToEditor = (content) => {
    if (editor) {
      const currentContent = editor.getText();
      editor.commands.setContent(currentContent ? currentContent + "\n\n" + content : content);
    }
  };

  const handleSubmit = async () => {
    const MIN_CHAR_LIMIT = 5;

    // Retrieve content directly from the editor instance
    const editorContent = editor ? editor.getText().trim() : "";

    if (!editorContent || !inputInstruction) {
      setErrorMessage("Both editor content and instructions are required.");
      return;
    }

    if (inputInstruction.trim().length < MIN_CHAR_LIMIT) {
      setErrorMessage(
        `Instructions must be at least ${MIN_CHAR_LIMIT} characters long.`
      );
      return;
    }

    if (inputInstruction.length > 200) {
      setErrorMessage(
        "Please reduce your instructions to 200 characters or fewer."
      );
      return;
    }

    const instructionToSend = inputInstruction;
    setInputInstruction("");
    setErrorMessage("");
    setCharLimitError(false);
    setIsLoading(true);

    try {
      const response = await fetch("/api/submit_edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: editorContent,
          instructions: instructionToSend,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setOutputContent(data.edited_content);
        if (editor) {
          editor.commands.setContent(data.edited_content);
        }
      } else {
        const errorData = await response.json();
        // Insert the new code here
        if (response.status === 429) {
          setErrorMessage("Global request limit reached. Please try again later.");
        } else {
          setErrorMessage(errorData.error || "Failed to edit content.");
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred while editing the content.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleExport = async () => {
    // Retrieve content directly from the editor instance as HTML
    const editorContent = editor ? editor.getHTML() : "";
  
    if (!editorContent || editorContent.trim() === "<p></p>") {
      setErrorMessage("Editor content is empty.");
      return;
    }
    try {
      const response = await fetch("/api/export_editor_content", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: editorContent }), // Send HTML content
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "editor_content.docx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setErrorMessage("");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Failed to export content.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while exporting the content.");
    }
  };

  const filteredClipboardItems = clipboardItems.filter((item) =>
    item.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputInstructionChange = (e) => {
    const value = e.target.value;
    setInputInstruction(value);

    if (value.length > 200) {
      setCharLimitError(true);
    } else {
      setCharLimitError(false);
    }
  };

  // Tooltip Handlers
  const handleMouseEnter = (e, content) => {
    const rect = e.target.getBoundingClientRect();
    setTooltipRect(rect);
    setTooltipContent(content);
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltipVisible(false);
      setTooltipContent("");
      setTooltipRect(null);
    }, 200);
  };

  const handleMouseMove = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
      tooltipTimeoutRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);

  const handleClearEditorContent = () => {
    setOutputContent("");
    if (editor) {
      editor.commands.clearContent();
    }
  };

  return (
    <div className="editor-container">
      <main className="editor-main">
        <header className="editor-header" style={{ alignItems: "center" }}>
          <h2 className="editor-title roboto-light">EDITOR</h2>
        </header>
        <p className="editor-description">
          Interested in customizing and refining your collected updates?
          Regxplora’s Editor allows you to import content from your clipboard
          for further transformation. Utilize generative AI to create tailored
          summaries, briefing notes, or other outputs based on custom
          instructions, or make direct edits within the editor. With options to
          filter your saved content, clear the clipboard, or export your work
          directly to Word, the Editor is designed to streamline your workflow.
          Simply start by copying an item from your saved list to the editor
          field.
        </p>

        {/* Split into two columns */}
        <div className="editor-columns">
          <button
            className="last-mob-btn mobile-btns export-btn"
            onClick={handleExport}
          >
            Export editor content to Word file
          </button>

          <button
            className="mobile-btns clear-editor-btn"
            onClick={handleClearEditorContent}
          >
            Clear editor content
          </button>

          <button
            className="mobile-btns clear-clipboard-btn"
            onClick={clearClipboard}
          >
            Clear all clipboard items
          </button>

          {/* Left Column: 3/4 width */}
          <div className="editor-left-column">
            <h3>Editor</h3>
            {/* TipTap Editor */}
            <div className="editor-output-container">
              <EditorContent editor={editor} />
            </div>

            {/* Input Field with Send Button */}
            <div className="editor-input-container">
              <div className="input-with-button">
                <textarea
                  type="text"
                  className="editor-input-field"
                  placeholder="Enter your instruction..."
                  rows="3"
                  value={inputInstruction}
                  onChange={handleInputInstructionChange}
                  onKeyDown={handleKeyDown}
                  disabled={isLoading}
                ></textarea>
                {/* Button with the SendIcon component placed inside the input field */}
                <button className="send-button" onClick={handleSubmit} disabled={isLoading}>
                  <SendIcon className="send-icon" />
                </button>
              </div>
            </div>
            {/* Error Message */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {charLimitError && (
              <p className="char-limit-error">
                Input exceeds the maximum allowed length of 200 characters.
              </p>
            )}
          </div>

          {/* Right Column: 1/4 width */}
          <div className="editor-right-column">
            <h3>Saved items</h3>
            <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder="Filter saved items by keyword..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Clipboard Items */}
            <div className="clipboard-items">
              {filteredClipboardItems.length === 0 ? (
                <p className="no-items-message">
                  You currently have no saved items
                </p>
              ) : (
                filteredClipboardItems.map((item) => (
                  <div key={item.id} className="clipboard-item">
                    {/* Nested div for date and content */}
                    <div className="clipboard-item-content">
                      {/* Display only the date */}
                      <span className="clipboard-item-date">
                        {new Date(item.date).toLocaleDateString()}
                      </span>
                      <p
                        onMouseEnter={(e) => handleMouseEnter(e, item.content)}
                        onMouseLeave={handleMouseLeave}
                        onMouseMove={handleMouseMove}
                      >
                        {item.content.length > 70
                          ? item.content.substring(0, 70) + "..."
                          : item.content}
                      </p>
                    </div>
                    {/* Container for buttons */}
                    <div className="clipboard-item-buttons">
                      <button
                        className="icon-button"
                        onClick={() => handleCopyToEditor(item.content)}
                        aria-label="Copy content"
                      >
                        <CopyIcon className="copy-icon" />
                      </button>
                      <button
                        className="icon-button"
                        onClick={() => removeClipboardItem(item.id)}
                        aria-label="Delete item"
                      >
                        <TrashIcon className="trash-icon" />
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>

            <button className="clear-clipboard-btn" onClick={clearClipboard}>
              Clear all clipboard items
            </button>
            <button
              className="clear-editor-btn"
              onClick={handleClearEditorContent}
            >
              Clear editor content
            </button>
            <button className="export-btn" onClick={handleExport}>
              Export editor content to Word file
            </button>
          </div>
        </div>
        {/* Render Tooltip */}
        {tooltipVisible && (
          <Tooltip
            text={tooltipContent}
            position={tooltipPosition}
            targetRect={tooltipRect}
          />
        )}
      </main>
    </div>
  );
};

export default Editor;