import React, { useState, useEffect } from "react";
import './Overview.css';
import { ReactComponent as OverviewIconDashboard } from './assets/icons/OverviewIconDashboard.svg';
import { ReactComponent as OverviewIconNewsfeed } from './assets/icons/OverviewIconNewsfeed.svg';
import { ReactComponent as OverviewIconExport } from './assets/icons/OverviewIconExport.svg';
import { ReactComponent as OverviewIconAlerts } from './assets/icons/OverviewIconAlerts.svg';
import { ReactComponent as OverviewIconChat } from './assets/icons/OverviewIconChat.svg';
import { ReactComponent as OverviewIconEditor } from './assets/icons/OverviewIconEditor.svg';
import { Link, useLocation } from "react-router-dom";
import DashboardRestrictionModal from "./DashboardRestrictionModal";

const Overview = () => {
  const location = useLocation();
  const [showDashboardRestrictionModal, setShowDashboardRestrictionModal] =
    useState(false);

  useEffect(() => {
    // Show the modal if the state indicates so
    if (location.state?.showDashboardRestrictionModal) {
      setShowDashboardRestrictionModal(true);
    }
  }, [location.state]);

  return (
    <div className="overview-container">
      {showDashboardRestrictionModal && (
        <DashboardRestrictionModal
          onClose={() => setShowDashboardRestrictionModal(false)}
        />
      )}
      <main className="overview-main">
        <div className="overview-section">
          <header className="overview-header" style={{ alignItems: 'center' }}>
            <h2 className="overview-title roboto-light">WELCOME TO REGXPLORA</h2>
          </header>
          <p className="overview-description">
          Regxplora is a global financial regulatory and central bank intelligence platform. At its core is an advanced engine powered by robotic process automation and generative artificial intelligence (AI), which gathers updates from close to 700 organizations worldwide through a fully automated, 24/7 process, organizing the information into a centralized, structured database. Regxplora is offered as a free resource for the financial regulatory and central bank community, providing tools to navigate the growing complexity of information. It organizes data in an accessible format that supports targeted filtering and utilizes generative AI to introduce new analysis tools.
            </p>
        </div>
        <div className="overview-section">
          <div className="box-container">
            <Link to="/dashboard" className="box-link">
              <div className="box">
                <h3 className="box-header">DASHBOARD</h3>
                <p className="box-text">Get an at-a-glance view of the most critical updates in our centralized dashboard</p>
                <OverviewIconDashboard className="box-icon" />
              </div>
            </Link>
            <Link to="/newsfeed" className="box-link">
              <div className="box">
                <h3 className="box-header">NEWSFEED</h3>
                <p className="box-text">Access and filter updates from over 700 bodies globally in our AI-powered newsfeed</p>
                <OverviewIconNewsfeed className="box-icon" />
              </div>
            </Link>
            <Link to="/newsfeed" className="box-link">
              <div className="box">
                <h3 className="box-header">EXPORT</h3>
                <p className="box-text">Easily export information of interest via Excel file for further use and processing</p>
                <OverviewIconExport className="box-icon" />
              </div>
            </Link>
            <Link to="/alerts" className="box-link">
              <div className="box">
                <h3 className="box-header">ALERTS</h3>
                <p className="box-text">Create custom alerts to receive updates in areas of interest directly to your inbox</p>
                <OverviewIconAlerts className="box-icon" />
              </div>
            </Link>
            <Link to="/chat" className="box-link">
              <div className="box">
                <h3 className="box-header">CHAT</h3>
                <p className="box-text">Directly interact with the intelligence housed in Regxplora via custom chatbot</p>
                <OverviewIconChat className="box-icon" />
              </div>
            </Link>
            <Link to="/editor" className="box-link">
              <div className="box">
                <h3 className="box-header">EDITOR</h3>
                <p className="box-text">Consolidate and customize information sourced via Regxplora with the help of AI</p>
                <OverviewIconEditor className="box-icon" />
              </div>
            </Link>
          </div>
        </div>
        <div className="overview-section">
          <p className="overview-paragraph">
          Over the next 6–12 months, the platform will be gradually enhanced with new features. These will include specialized trackers to provide more targeted information for different types of updates, an improved suite of AI-powered research and analysis tools and further refinement and expansion of the existing news tracker capabilities. Updates will be shared here, and a feature log will soon be introduced to make it easier to track changes.
            <span style={{ display: 'block', marginTop: '30px', color: '#000', fontWeight: 'bold' }}>
              Please note that the final data migration remains underway and the database is currently not yet complete. This can affect the quality and comprehensiveness of Regxplora's output. The migration will be completed by Sunday, December 8.
            </span>
          </p>
        </div>
      </main>
    </div>
  );
};

export default Overview;