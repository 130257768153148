// Login.js

import React, { useState } from 'react';
import './Login.css'; // Import the new CSS file for Login
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Login({ setIsAuthenticated }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    if (response.ok) {
      // Clear Session Storage statuses and clipboard items
      sessionStorage.removeItem('newsItemStatuses');
      sessionStorage.removeItem('clipboardItems');

      setIsAuthenticated(true); // Update authentication status
      navigate('/overview'); // Redirect to overview
    } else {
      setError(data.error);
    }
  };

  return (
    <div className="login-container">
      <main className="login-main">
        <header className="login-header">
          <h2 className="login-title roboto-light">LOGIN</h2>
        </header>
        <p className="login-description">
          Welcome back! Please enter your email and password to log in to your account.
        </p>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-form-group">
            <label htmlFor="email" className="login-label">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="login-input"
              placeholder="Your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="login-form-group">
            <label htmlFor="password" className="login-label">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              className="login-input"
              placeholder="Your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button className="login-section-btn" type="submit">
            LOGIN
          </button>
        </form>
        <p className="forgot-password-link">
          <Link to="/reset_password">Forgot Password?</Link>
        </p>
        {/* Move the error message here */}
        {error && <p className="login-error">{error}</p>}
      </main>
    </div>
  );
}

export default Login;